.listItemBox {
    display: flex !important;
}

.mustHaveItemBox {
    width: 20%;
    height: 20%;
    margin: 5px;
    border-style: solid;
    border-color: aqua;
}